.spacer {
  margin-top: 15px;
}
.MuiCollapse-wrapperInner
  > #panel1a-content
  > div
  > div:nth-child(3)
  > div
  > div.MuiToolbar-root
  > div.MuiFormControl-root {
  display: none;
}
.MuiCollapse-wrapperInner > #panel1a-content > div > div:nth-child(3) {
  width: 100%;
  box-shadow: none;
  margin: 0 auto !important;
}
.MuiCollapse-wrapperInner > #panel1a-content > div > div:nth-child(3) > div {
  box-shadow: none;
}

#panel1a-content
  > div
  > div:nth-child(3)
  > div
  > div.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
  display: none;
}

#panel1a-content > div {
  margin: 0 auto !important;
}

.MuiCollapse-wrapperInner
  > #panel1a-content
  > div
  > div:nth-child(4)
  > div
  > div.MuiToolbar-root
  > div.MuiFormControl-root {
  display: none;
}

.MuiCollapse-wrapperInner > #panel1a-content > div > div:nth-child(4) {
  width: 100%;
}

.MuiCollapse-wrapperInner > #panel1a-content > div > div:nth-child(4) > div {
  box-shadow: none;
}
